import PropTypes from 'prop-types'
import Box from '@mui/material/Box';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import 'react-toastify/dist/ReactToastify.css';
import TextField from '@mui/material/TextField';


const OidcModal = ({ isOpen, closeModal }) => {
    const [oid4vciVersion, setOid4vciVersion] = useState(() => localStorage.getItem('oid4vciVersion') || '');
    const [oid4vpVersion, setOid4vpVersion] = useState(() => localStorage.getItem('oid4vpVersion') || '');
    const [issuanceMode, setIssuanceMode] = useState(() => localStorage.getItem('issuanceMode') || '');
    const [oid4vciPrefix, setOid4vciPrefix] = useState(() => localStorage.getItem('oid4vciPrefix') || '');
    const [oid4vpPrefix, setOid4vpPrefix] = useState(() => localStorage.getItem('oid4vpPrefix') || '');

    const theme = useTheme();

    if (!isOpen) return null;

    const listOfOid4vciVersions = ["oid4vci_draft8", "oid4vci_draft13"];
    const listOfOid4vpVersions = ["oid4vp_1-07", "oid4vp_draft20", "eudi_draft20"];
    const listOfIssuanceModes = ["authorization_code", "pre-authorized_code"];
    const listOfOid4vciPrefixes = ["openid-credential-offer://?", "haip://?"];
    const listOfOid4vpPrefixes = ["openid-vc://?", "openid-vp://?", "eudi-openid4vp://?", "haip://?"];

    const notifySuccess = (message) => {
        toast.success(message, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
        });
    };

    const handleSave = () => {
        localStorage.setItem('oid4vciVersion', oid4vciVersion);
        localStorage.setItem('oid4vpVersion', oid4vpVersion);
        localStorage.setItem('issuanceMode', issuanceMode);
        localStorage.setItem('oid4vciPrefix', oid4vciPrefix);
        localStorage.setItem('oid4vpPrefix', oid4vpPrefix);
        notifySuccess("OIDC versions saved successfully");
        closeModal();
    };

    return (
        <Modal
            open={isOpen}
            onClose={closeModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    top: '50%',
                    left: '50%',
                    width: '80%',
                    maxWidth: '400px',
                    minWidth: '200px',
                    p: 2,
                    border: 'none',
                    borderRadius: '8px',
                    position: 'absolute',
                    bgcolor: '#fff',
                    transform: 'translate(-50%, -50%)',
                    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.24)',
                }}
            >
                <h2 style={{ marginTop: '0px', marginBottom: '8px' }} id="modal-title">Enter OIDC versions</h2>
                <TextField
                    select
                    label="OID4VCI"
                    value={oid4vciVersion}
                    onChange={(e) => setOid4vciVersion(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {listOfOid4vciVersions.map((version) => (
                        <MenuItem key={version} value={version}>
                            {version}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="OID4VP"
                    value={oid4vpVersion}
                    onChange={(e) => setOid4vpVersion(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {listOfOid4vpVersions.map((version) => (
                        <MenuItem key={version} value={version}>
                            {version}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="Issuance Mode"
                    value={issuanceMode}
                    onChange={(e) => setIssuanceMode(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {listOfIssuanceModes.map((mode) => (
                        <MenuItem key={mode} value={mode}>
                            {mode}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="OID4VCI Prefix"
                    value={oid4vciPrefix}
                    onChange={(e) => setOid4vciPrefix(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {listOfOid4vciPrefixes.map((mode) => (
                        <MenuItem key={mode} value={mode}>
                            {mode}
                        </MenuItem>
                    ))}
                </TextField>

                <TextField
                    select
                    label="OID4VP Prefix"
                    value={oid4vpPrefix}
                    onChange={(e) => setOid4vpPrefix(e.target.value)}
                    fullWidth
                    margin="normal"
                >
                    {listOfOid4vpPrefixes.map((mode) => (
                        <MenuItem key={mode} value={mode}>
                            {mode}
                        </MenuItem>
                    ))}
                </TextField>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2.5,
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleSave}
                        sx={{ color: theme.palette.button.color, backgroundColor: theme.palette.button.backgroundColor, '&:hover': { backgroundColor: theme.palette.button.backgroundColor } }}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}

OidcModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
};

export default OidcModal;