import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

const Issuer = React.lazy(() => import('./pages/Issuer'));
const Verifier = React.lazy(() => import('./pages/Verifier'));
const Authorize = React.lazy(() => import('./pages/Authorize'));

const Issuance = React.lazy(() => import('./components/Issuance'));

export const router = createBrowserRouter([
  { path: "/", element: <Issuer /> },
  { path: "/issuer", element: <Issuer /> },
  { path: "/issuer/:vcName", element: <Issuance /> },
  { path: "/verifier", element: <Verifier /> },
  { path: "/verifier/:vcName", element: <Verifier /> },
  { path: "/authorize", element: <Authorize /> },
  { path: "*", element: <Navigate to="/" /> },
]);