import { router } from "router";
import Box from '@mui/material/Box';
import OidcModal from './OidcModal';
import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { useState, useEffect } from 'react';


function Header() {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [oidcVersionsModal, setOidcVersionsModal] = useState(false);
    const handleOpenOidcVersionsModal = () => setOidcVersionsModal(true);
    const handleCloseOidcVersionsModal = () => setOidcVersionsModal(false);

    const pages = ['Issuer', 'Verifier'];

    const theme = useTheme();

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = (event) => {
        setAnchorElNav(null);
        if (pages.includes(event)) {
            setTimeout(() => {
                router.navigate(`/${event.toLowerCase()}`);
            }, 10);
        }
    };

    useEffect(() => {
        if (!localStorage.getItem('oid4vciVersion')) localStorage.setItem('oid4vciVersion', 'oid4vci_draft13');
        if (!localStorage.getItem('oid4vpVersion')) localStorage.setItem('oid4vpVersion', 'oid4vp_draft20');
        if (!localStorage.getItem('issuanceMode')) localStorage.setItem('issuanceMode', 'authorization_code');
        if (!localStorage.getItem('oid4vciPrefix')) localStorage.setItem('oid4vciPrefix', 'haip://?');
        if (!localStorage.getItem('oid4vpPrefix')) localStorage.setItem('oid4vpPrefix', 'haip://?');
    }, []);

    return (
        <AppBar position="static" sx={{ bgcolor: theme.palette.header.backgroundColor }}>
            <Container maxWidth={false} style={{ width: '100%' }}>
                <Toolbar disableGutters sx={{ minHeight: '8vh', height: '8vh'}}>
                    <Box onClick={() => window.location.href = "/"} sx={{ display: { xs: 'none', md: 'flex', alignItems: 'center', cursor: 'pointer' }, mr: 1 }}>
                        <Typography
                            component="a"
                            variant="h6"
                            noWrap
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontWeight: 700,
                                color: theme.palette.primary.main,
                            }}
                        >
                            &nbsp;&nbsp;Credential Issuer
                        </Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            sx={{
                                color: theme.palette.secondary.main,
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                            <MenuItem onClick={() => {handleCloseNavMenu(null); handleOpenOidcVersionsModal();}}>
                                <Typography textAlign="center">OIDC Options</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Box
                        onClick={() => window.location.href = "/"}
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            mr: 1,
                        }}
                    >
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            sx={{
                                ml: 2,
                                display: 'flex',
                                fontWeight: 700,
                                textDecoration: 'none',
                                color: theme.palette.primary.main,
                            }}
                        >
                            Credential Issuer
                        </Typography>
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={() => handleCloseNavMenu(page)}
                                sx={(theme) => ({ my: 2, color: theme.palette.text.primary, display: 'block', fontWeight: 'bold', textTransform: 'none' })}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
                        <Button
                            onClick={handleOpenOidcVersionsModal}
                            sx={{ my: 2, color: theme.palette.text.primary, display: 'block', fontWeight: 'bold', textTransform: 'none' }}
                        >
                            OIDC Options
                        </Button>
                    </Box>

                </Toolbar>
            </Container>
            <OidcModal isOpen={oidcVersionsModal} closeModal={handleCloseOidcVersionsModal} />
        </AppBar>
    );
}

export default Header;