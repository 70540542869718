import React from 'react';
import { router } from './router';
import Header from './components/Header/Header';
import { ToastContainer } from 'react-toastify';
import { RouterProvider } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0000a0'
    },
    secondary: {
      main: '#000000'
    },
    button: {
      color: '#ffffff',
      backgroundColor: '#0000a0'
    },
    header: {
      backgroundColor: '#ffffff'
    }
  }
});

const AppRoutes = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <React.Suspense fallback={<div>Loading...</div>}>
      <Header />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <RouterProvider router={router} />
    </React.Suspense>
  </ThemeProvider>
);

export default AppRoutes;